import {defineMessages, InjectedIntl} from "react-intl";
import {Api} from "../api/InternalApi";
import {actions as notificationActions} from "../common/ui/notification/actions";
import {executeIfNoWarnings, registerWarningAction} from "../common/ui/warnings/Warnings";
import {createAsyncActionTypes} from "../common/util/asyncdata/actionUtil";
import {
  createPagedDataActions,
  createPagedDataActionTypes,
  selectors as pagedDataSelectors,
} from "../common/util/pagination/PaginationUtil";
import {Product} from "../products/model";
import {filtersEqual, ServiceFilter} from "./filter/model";
import {Service, ServiceTypeDetails} from "./model";
import {selectors} from "./selectors";

export const LOAD_SERVICE_PRODUCTS: "controlRoom/LOAD_SERVICE_PRODUCTS" = "controlRoom/LOAD_SERVICE_PRODUCTS";
const loadServiceProductActionTypes = createAsyncActionTypes(LOAD_SERVICE_PRODUCTS);

export const SERVICE_ACTION_TYPENAME = "SERVICE";
const pagedDataActionTypes = createPagedDataActionTypes(SERVICE_ACTION_TYPENAME);

const ACTIONS_MESSAGES = defineMessages({
  startService: {
    id: "studio.services.actions.start-service-error",
    defaultMessage: ": Could not start Service with id {serviceID}.",
  },
});

const fetchPage = (page: number, state, api: Api) => {
  const filter = selectors.getFilter(state);
  const pagedDataState = selectors.getPagedState(state);
  const pageSize = pagedDataSelectors.getPageSize(pagedDataState);
  const offset = page * pageSize;
  const filterWithPagination: ServiceFilter = Object.assign({}, filter, {offset, maxResults: pageSize});
  return api.listServices(filterWithPagination).then((datas) => ({
    datas,
    pageValidationInfo: {
      filter,
    },
  }));
};

const isPageStillValid = (pageValidationInfo, state) => {
  return filtersEqual(pageValidationInfo.filter, selectors.getFilter(state));
};

const fetchById = (id: string, state, api: Api) => {
  return api.getFullServiceById(id);
};

const pagedDataActions = createPagedDataActions<Service>(
    SERVICE_ACTION_TYPENAME,
    fetchPage,
    isPageStillValid,
    fetchById,
    selectors.getPagedState,
);

export const actionTypes = Object.assign({}, {
  SET_FILTER: "controlRoom/SET_SERVICE_FILTER",
  START_SERVICE: "controlRoom/START_SERVICE",
  STOP_SERVICE: "controlRoom/STOP_SERVICE",
  UPDATE_SERVICE: "controlRoom/UPDATE_SERVICE",
  LOAD_SERVICE_PRODUCTS_STARTED: loadServiceProductActionTypes.STARTED,
  LOAD_SERVICE_PRODUCTS_SUCCESS: loadServiceProductActionTypes.SUCCESS,
  LOAD_SERVICE_PRODUCTS_ERROR: loadServiceProductActionTypes.ERROR,
  LOAD_SERVICE_PRODUCTS_RESET: loadServiceProductActionTypes.RESET,
  LOAD_ALL_SERVICE_TYPES: "controlRoom/LOAD_ALL_SERVICE_TYPES",
  LOAD_ENABLED_SERVICE_TYPES: "controlRoom/LOAD_ENABLED_SERVICE_TYPES",
}, pagedDataActionTypes);

const startService = (serviceId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.startService(serviceId).then(
        (service) => dispatch({
          type: actionTypes.START_SERVICE,
          payload: {
            service,
            id: serviceId,
          },
        }),
    ).catch((error) => {
      dispatch(notificationActions.showWarningNotification(
          error.message + ": Could not start Service with id " + serviceId + "."));
      throw error;
    });
  };
};

const stopService = (serviceId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.stopService(serviceId).then(
        () => dispatch({
          type: actionTypes.STOP_SERVICE,
          payload: {id: serviceId},
        }),
    ).catch((error) => {
      dispatch(notificationActions.showWarningNotification(
          error.message + ": Could not stop Service with id " + serviceId + "."));
      throw error;
    });
  };
};

const createService = (service: Service) => {
  return (dispatch, getState, {getApi}): Promise<Service> => {
    const api = getApi(getState());
    return api.createService(service).then((addedService) => {
      dispatch(actions.clearData());
      return addedService;
    });
  };
};

const updateService = (service: Service) => {
  return (dispatch, getState, {getApi}): Promise<Service> => {
    const api = getApi(getState());
    return api.updateService(service).then((updatedService) => {
      const updateServiceAction = {
        type: actionTypes.UPDATE_SERVICE,
        payload: {
          service: updatedService,
        },
      };
      dispatch(updateServiceAction);
      return updatedService;
    });
  };
};

const deleteService = (serviceId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.deleteService(serviceId).then((removedService) => {
      dispatch(actions.clearData());
    });
  };
};

const loadServiceProducts = (serviceId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.getProductsForService(serviceId).then((products) => {
      dispatch({
        type: loadServiceProductActionTypes.SUCCESS,
        payload: {serviceId, products},
      });
    });
  };
};

const addProductToService = (serviceId: string, productId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.addProductToService(serviceId, productId).then(() => {
      dispatch(loadServiceProducts(serviceId));
    });
  };
};

const ADD_PRODUCT_TO_SERVICE_WARNING_ID = "addProductToService";
registerWarningAction(ADD_PRODUCT_TO_SERVICE_WARNING_ID, addProductToService);

const SERVICES_MESSAGES = defineMessages({
  replaceSameNameProductHeader: {
    id: "studio.settings.services.replace-same-name-product-header",
    defaultMessage: "Adding product id {ProductId}",
  },
  replaceSameNameProductBody: {
    id: "studio.settings.services.replace-same-name-product-body",
    defaultMessage: "You are about to replace it with a product of the same name. Are you sure you want to continue?",
  },
  replaceSameNameProductWhileProcessingMessage: {
    id: "studio.settings.services.replace-same-name-product-while-processing-message",
    defaultMessage: "Replacing product...",
  },
  replaceSameNameProductForceButton: {
    id: "studio.settings.services.replace-same-name-product-force-button",
    defaultMessage: "Replace Product",
  },
  replaceSameNameProductWarningMessage: {
    id: "studio.settings.services.replace-same-name-product-warning-message",
    defaultMessage: "A product with the name {ProductName} already exists in this service.",
  },
  removeHeader: {
    id: "studio.services.remove-service-dialog.header",
    defaultMessage: "Removing service id {id}",
  },
  removeBody: {
    id: "studio.services.remove-service-dialog.body",
    defaultMessage: "Users currently consuming the service will be disconnected due to this change. Are you sure you want to continue?",
  },
  removeButton: {
    id: "studio.services.remove-service-dialog.button",
    defaultMessage: "Remove Service",
  },
  serviceRunningWarning: {
    id: "studio.services.remove-service-dialog.service-running-warning",
    defaultMessage: "Service is running",
  },
});

const willReplace = (serviceId: string, productId: string, productName: string, currentProducts: Product[],
                     intl: any) => {
  if (currentProducts.filter((product) => product.name === productName).length === 1) {
    return Promise.resolve(
        [intl.formatMessage(SERVICES_MESSAGES.replaceSameNameProductWarningMessage, {ProductName: productName})]);
  } else {
    return Promise.resolve([]);
  }
};

const addProductToServiceIfNoWarnings = (serviceId: string, productId: string, productName: string,
                                         currentProducts: Product[], intl: any) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return dispatch(executeIfNoWarnings(
        willReplace(serviceId, productId, productName, currentProducts, intl),
        addProductToService(serviceId, productId),
        {
          headerText: intl.formatMessage(SERVICES_MESSAGES.replaceSameNameProductHeader, {ProductId: productId}),
          bodyText: intl.formatMessage(SERVICES_MESSAGES.replaceSameNameProductBody),
          whileExecutingText: intl.formatMessage(SERVICES_MESSAGES.replaceSameNameProductWhileProcessingMessage),
          forceActionCreatorId: ADD_PRODUCT_TO_SERVICE_WARNING_ID,
          forceActionParams: [serviceId, productId, productName],
          forceButtonText: intl.formatMessage(SERVICES_MESSAGES.replaceSameNameProductForceButton),
          forceButtonIcon: "check",
        },
    ));
  };
};

const addProductsToService = (serviceId: string, productIds: string[]) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.addProductsToService(serviceId, productIds).then(() => {
      dispatch(loadServiceProducts(serviceId));
    });
  };
};

const setServiceProducts = (serviceId: string, products: Product[]) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.setServiceProducts(serviceId, products).then(() => {
      dispatch(loadServiceProducts(serviceId));
    });
  };
};

const removeProductFromService = (serviceId: string, productId: string) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return api.removeProductFromService(serviceId, productId).then(() => {
      dispatch(loadServiceProducts(serviceId));
    });
  };
};

const REMOVE_SERVICE_WARNING_ID = "removeService";
registerWarningAction(REMOVE_SERVICE_WARNING_ID, deleteService);

const deleteServiceIfNoWarnings = (serviceId: string, intl: InjectedIntl) => {
  return (dispatch, getState, {getApi}) => {
    const api = getApi(getState());
    return dispatch(executeIfNoWarnings(
        api.isServiceStarted(serviceId).then((started) => started ? [intl.formatMessage(SERVICES_MESSAGES.serviceRunningWarning)] : []),
        deleteService(serviceId),
        {
          headerText: intl.formatMessage(SERVICES_MESSAGES.removeHeader, {id: serviceId}),
          bodyText: intl.formatMessage(SERVICES_MESSAGES.removeBody),
          forceActionCreatorId: REMOVE_SERVICE_WARNING_ID,
          forceActionParams: [serviceId],
          forceButtonText: intl.formatMessage(SERVICES_MESSAGES.removeButton),
          forceButtonIcon: "delete",
        },
    ));
  };
};

const loadAllServiceTypes = () => {
  return (dispatch, getState, {getApi}) => {
    getApi(getState()).getAllServiceTypes().then(
        (serviceConfigurations) => dispatch({
          type: actionTypes.LOAD_ALL_SERVICE_TYPES,
          payload: {serviceConfigurations},
        }));
  };
};

const loadEnabledServiceTypes = () => {
  return (dispatch, getState, {getApi}): Promise<ServiceTypeDetails> => {
    return getApi(getState()).getEnabledServiceTypes().then((serviceConfigurations) => {
      dispatch(({
        type: actionTypes.LOAD_ENABLED_SERVICE_TYPES,
        payload: {serviceConfigurations},
      }));
      return serviceConfigurations;
    });
  };
};

const setFilter = (newFilter: ServiceFilter) => {
  return {
    type: actionTypes.SET_FILTER,
    payload: newFilter,
  };
};

const validateProduct = (service: Service, product: Product) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).validateProduct(service.id, product.id);
  };
};

const validateProducts = (service: Service, products: Product[]) => {
  return (dispatch, getState, {getApi}) => {
    return getApi(getState()).validateProducts(service.id, products.map((product) => product.id));
  };
};

export const actions = Object.assign({}, {
  setFilter,
  createService,
  deleteService,
  deleteServiceIfNoWarnings,
  startService,
  stopService,
  updateService,
  loadServiceProducts,
  addProductToService,
  addProductToServiceIfNoWarnings,
  addProductsToService,
  setServiceProducts,
  removeProductFromService,
  loadAllServiceTypes,
  loadEnabledServiceTypes,
  validateProduct,
  validateProducts,
}, pagedDataActions);
