import * as React from "react";
import {Col} from "react-bootstrap";
import {RouteComponentProps, withRouter} from "react-router-dom";

// tslint:disable-next-line
const userGuide = require("./../../docs/articles/userguide/studio/UserGuide") as string;

export class HelpPageComponent extends React.Component<RouteComponentProps<{section: string}>, {}> {

  _tocHTML: string;
  _contentHTML: string;
  _adocDiv: HTMLDivElement;

  constructor(props) {
    super(props);
    const adocDom = document.createElement("div");
    adocDom.innerHTML = userGuide;
    this._tocHTML = adocDom.querySelector("#toc").innerHTML;
    this._contentHTML = "";
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < adocDom.children.length; i++) {
      if (adocDom.children[i].id !== "toc") {
        this._contentHTML += adocDom.children[i].outerHTML;
      }
    }
  }

  scrollToSection = (sectionId: string) => {
    const linkTarget = document.getElementById(sectionId);
    if (linkTarget) {
      linkTarget.scrollIntoView({
        block: "start", //scroll so that element is at the top of the view
        behavior: "smooth", //use smooth scrolling (FF only)
      });
    }
  }

  componentDidMount() {
    //fix links in the adoc, they don't play nice with react-router-dom's hashHistory that's used in ControlRoom

    if (this.props.match.params.section) {
      this.scrollToSection(this.props.match.params.section);
    }

    //select all links that link to somewhere in the adoc itself, and let the scroll into the view
    const localDocumentAdocLinks = this._adocDiv.querySelectorAll("a[href^=\"#\"]");
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < localDocumentAdocLinks.length; i++) {
      const link = localDocumentAdocLinks[i] as HTMLAnchorElement;
      link.onclick = (event) => {
        event.preventDefault();
        const sectionId = link.href.slice(link.href.indexOf("#") + 1);
        this.props.history.push("/help/" + sectionId);
        this.scrollToSection(sectionId);
      };
    }
  }

  render() {
    return (
      <div ref={(ref) => {this._adocDiv = ref; }} className="helppage">
        <Col xs={4} dangerouslySetInnerHTML={{__html: this._tocHTML}} className="toc">
        </Col>
        <Col xsOffset={4} xs={8} dangerouslySetInnerHTML={{__html: this._contentHTML}} className="content">
        </Col>
      </div>
    );
  }
}

export const HelpPage = withRouter(HelpPageComponent);
