import * as React from "react";
import {Button} from "react-bootstrap";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {actions} from "./actions";
import {Service} from "./model";

interface ServiceDeleteButtonStateProps {
  service: Service;
}

interface ServiceStartStopButtonOtherProps {
  bsSize?: string;
  disabled?: boolean;
}

interface ServiceDeleteButtonDispatchProps {
  deleteButtonClicked(): void;
}

type ServiceDeleteButtonProps = ServiceDeleteButtonStateProps & ServiceDeleteButtonDispatchProps & ServiceStartStopButtonOtherProps;

export class ServiceDeleteButtonComponent extends React.Component<ServiceDeleteButtonProps & InjectedIntlProps, {}> {

  render() {
    return (
        <Button className="btn-icon"
                style={{backgroundColor: "transparent", color: "currentColor"}}
                onClick={this.props.deleteButtonClicked}
                bsSize={this.props.bsSize as any || null}
                disabled={this.props.disabled}>
          <LcdIcon icon="delete"/>
        </Button>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    service: ownProps.service,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteButtonClicked: () => dispatch(actions.deleteServiceIfNoWarnings(ownProps.service.id, ownProps.intl)),
  };
};

export const ServiceDeleteButton = injectIntl(connect(mapStateToProps, mapDispatchToProps)(ServiceDeleteButtonComponent));
