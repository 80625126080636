import * as React from "react";
import {Breadcrumb, Button, ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {ControlRoomTable} from "../../common/ui/table/ControlRoomTable";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {DirectoryItem} from "../../model";
import {DirectoryItemsColumnFactory} from "./DirectoryItemsColumnFactory";

interface ChooseFolderFormState {
  directoryPath: string;                      // Path of the current directory
  directoryItems: DirectoryItem[];            // Items in the current directory
  folderCandidate: string;                    // Directory path that will be added as a data root if 'Add data root' button pressed
  folderCandidateValidation: "error" | null;  // Feedback when typing a wrong data root candidate
}

interface ChooseFolderFormProps {
  initialPath?: string;
  buttonText: string;
  canSelectFile: boolean;

  handleChoose(contentRootPath: string);
}

const getDirectoryItemsListDimensions = (parent: HTMLElement) => {
  const paddingLeft = parseFloat(getComputedStyle(parent).paddingLeft) || 0;
  const paddingRight = parseFloat(getComputedStyle(parent).paddingRight) || 0;
  return {
    width: parent.clientWidth - paddingLeft - paddingRight,
    height: 400,
  };
};

const DirectoryItemsListComponent = ControlRoomTable(new DirectoryItemsColumnFactory(),
    getDirectoryItemsListDimensions);

class ChooseFolderFormComponent extends React.Component<ChooseFolderFormProps & WithApiProperties & InjectedIntlProps, ChooseFolderFormState> {

  constructor(props) {
    super(props);
    this.state = {
      directoryPath: "/",
      directoryItems: [],
      folderCandidate: "/",
      folderCandidateValidation: null,
    };
  }

  componentDidMount() {
    const {initialPath} = this.props;
    if (initialPath) {
      this.changeDirectory(initialPath);
    } else {
      this.changeDirectory("/");
    }
  }

  changeDirectory = (directoryPath: string) => {
    this.props.api.browse(directoryPath).then((directoryItems) => {
      this.setState({
        directoryItems,
        directoryPath,
        folderCandidate: directoryPath,
        folderCandidateValidation: null,
      });
    }, (error) => {
      this.setState(Object.assign({}, this.state, {folderCandidateValidation: "error"}));
    });
  }

  generateBreadCrumb = () => {
    const breadCrumbElementsArray = this.state.directoryPath.split(/[\\|/]/);

    const breadCrumbElements = [];
    for (const element of breadCrumbElementsArray) {
      let breadCrumbElement = null;
      if (element && element.trim() !== "") {
        breadCrumbElement = (<Button bsStyle="link" bsSize="small"
                                     onClick={() => {
                                       this.handleBreadCrumbClick(element);
                                     }}>{element}</Button>);
        breadCrumbElements.push((<Breadcrumb.Item key={element} active> {breadCrumbElement} </Breadcrumb.Item>));
      }
    }

    return (
        <Breadcrumb>
          {breadCrumbElements}
        </Breadcrumb>
    );
  }

  handleBreadCrumbClick = (elementToNavigateTo) => {
    const index = this.state.folderCandidate.indexOf(elementToNavigateTo);
    if (index >= 0) {
      this.changeDirectory(this.state.folderCandidate.substr(0, index + elementToNavigateTo.length) + "/");
    } else {
      // Go to root if directory is not found
      this.changeDirectory("/");
    }
  }

  handleDoubleClick = (event: React.MouseEvent<any>, index: number) => {
    const directoryItem = this.state.directoryItems[index];
    if (directoryItem.directory) {
      this.changeDirectory(directoryItem.absolutePath);
    }
  }

  handleRowSelect = (selectedItems: DirectoryItem[]) => {
    this.setState(Object.assign({}, this.state, {
      folderCandidate: this.findFolderCandidate(selectedItems),
      folderCandidateValidation: null,
    }));
  }

  canClickRow = (clickedItem: DirectoryItem) => {
    return this.props.canSelectFile || clickedItem.directory;
  }

  findFolderCandidate = (selectedItems: DirectoryItem[]) => {
    let contentRootCandidate = this.state.directoryPath; // Use current directory path if no items (or only files) selected
    for (const selectedItem of selectedItems) {
      if (selectedItem) {
        // Select first directory in case of multiple selection
        contentRootCandidate = selectedItem.absolutePath;
        break;
      }
    }
    return contentRootCandidate;
  }

  handleChooseFolder = () => {
    this.props.handleChoose(this.state.folderCandidate);
  }

  handleContentRootInputChanged = (e) => {
    this.setState(Object.assign({}, this.state, {
      folderCandidate: (e.target as any).value,
      folderCandidateValidation: null,
    }));
  }

  handleContentRootInputEnterPressed = (e) => {
    if (e.keyCode === 13) {
      this.changeDirectory(this.state.folderCandidate);
      e.preventDefault();
    }
  }

  render() {
    const {buttonText, intl} = this.props;
    return (
        <div>
          {this.generateBreadCrumb()}
          <Button bsStyle="link" bsSize="xsmall" onClick={() => {
            this.changeDirectory("/");
          }}>
            <FormattedMessage id="studio.settings.add-root-form.back-button" defaultMessage="Back to root"/>
          </Button>
          <DirectoryItemsListComponent isPaged={false}
                                       intl={intl}
                                       data={this.state.directoryItems}
                                       isAllDataLoaded={true}
                                       loadData={() => undefined}
                                       canClickRow={this.canClickRow}
                                       onRowDoubleClick={this.handleDoubleClick}
                                       onRowSelect={this.handleRowSelect}
                                       noItemsFoundMessage={<FormattedMessage
                                           id="studio.settings.add-root-form.empty-folder"
                                           defaultMessage="Empty directory"/>}
          />

          <form>
            <FormGroup controlId="formBasicText" validationState={this.state.folderCandidateValidation}>
              <ControlLabel><FormattedMessage id="studio.settings.add-root-form.form-header"
                                              defaultMessage="Location"/></ControlLabel>
              <FormControl
                  type="text"
                  value={this.state.folderCandidate}
                  onChange={this.handleContentRootInputChanged}
                  onKeyDown={this.handleContentRootInputEnterPressed}
              />
            </FormGroup>
          </form>

          <Button onClick={this.handleChooseFolder} block>{buttonText}</Button>
        </div>
    );
  }
}

export const ChooseFolderForm = injectIntl(WithApi(ChooseFolderFormComponent));
