import * as React from "react";
import {Button} from "react-bootstrap";
import {defineMessages, injectIntl} from "react-intl";
import {LcdIcon} from "../../common/ui/icon/LcdIcon";
import {WithApi, WithApiProperties} from "../../common/util/WithApi";
import {AddFolderDialog} from "./ChooseFolderDialog";
import {DataRootList} from "./DataRootList";
import {DataRoot} from "./model";
import InjectedIntlProps = ReactIntl.InjectedIntlProps;

interface DataRootsPageState {
  showAddDataRootDialog: boolean;
}

const DATA_ROOT_MESSAGES = defineMessages({
  header: {
    id: "studio.settings.data-roots-page.header",
    defaultMessage: "Data roots",
  },
  addDataRoot: {
    id: "studio.settings.data-roots-page.add-data-root",
    defaultMessage: "Add data root",
  },
  description: {
    id: "studio.settings.data-roots-page.description",
    defaultMessage: "Data roots are entry points where LuciadFusion Studio looks for data. Every data root has a matching crawling job, which takes care of the discovery.",
  },
});

class DataRootsPageComponent extends React.Component<WithApiProperties & InjectedIntlProps, DataRootsPageState> {

  constructor(props) {
    super(props);
    this.state = {showAddDataRootDialog: false};
  }

  componentDidMount() {
    this.setState({showAddDataRootDialog: this.state.showAddDataRootDialog});
  }

  openAddDataRootDialog = () => {
    this.setState({showAddDataRootDialog: true});
  }

  closeAddDataRootDialog = () => {
    this.setState({showAddDataRootDialog: false});
  }

  handleAdd = (dataRoot: DataRoot) => {
    return this.props.api.registerContentRoot(dataRoot);
  }

  render() {
    const {intl} = this.props;
    const addDataRootMessage = intl.formatMessage(DATA_ROOT_MESSAGES.addDataRoot);
    return (
        <div>
          <h1>
            {intl.formatMessage(DATA_ROOT_MESSAGES.header)}
          </h1>
          <hr/>
          <p>
            {intl.formatMessage(DATA_ROOT_MESSAGES.description)}
          </p>
          <Button className="user-tour-add-data-root-button" onClick={this.openAddDataRootDialog}>
            <LcdIcon icon={"plus"}/>
            {addDataRootMessage}
          </Button>
          <div> {/* This empty div is necessary! it's used by ControlRoomTable to calculate its dimensions.*/}
            <DataRootList className="user-tour-data-roots-list" />
          </div>
          <AddFolderDialog show={this.state.showAddDataRootDialog}
                           onHide={this.closeAddDataRootDialog}
                           handleChoose={this.handleAdd}
                           title={addDataRootMessage}
                           canSelectFile={false}
                           buttonText={addDataRootMessage}
          />
        </div>
    );
  }
}

export const DataRootsPage = WithApi(injectIntl(DataRootsPageComponent));
