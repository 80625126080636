import * as React from "react";
import {Sizes} from "react-bootstrap";
import * as Button from "react-bootstrap/lib/Button";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {LcdIcon} from "../../common/ui/icon/LcdIcon";

const CLEAR_COPIED_TEXT_MILLIS = 3000;

interface CopyToClipboardButtonProps {
  label: string | JSX.Element;
  text: string;
  bsSize?: Sizes;
}

interface CopyToClipboardButtonState {
  copySupported: boolean;
  copied: boolean;
}

const COPY_MESSAGES = defineMessages({
  copied: {id: "studio.services.fileserver.copy-to-clipboard.text", defaultMessage: "Copied"},
});

export class CopyToClipboardButtonComponent extends React.Component<CopyToClipboardButtonProps & InjectedIntlProps, CopyToClipboardButtonState> {

  _timer = null;

  constructor(props) {
    super(props);

    const isCopySupported = document.queryCommandSupported("copy");

    this.state = {
      copySupported: isCopySupported,
      copied: false,
    };
  }

  clearCopyState = () => {
    this.setState({copied: false});
    this._timer = null;
  }

  copy = () => {
    const {text} = this.props;

    // credits to https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript

    const textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = "0px";
    textArea.style.left = "0px";

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = "0px";

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let successful = false;
    try {
      successful = document.execCommand("copy");
    } catch (err) {
      //tslint:disable-next-line
      console.log(err);
      successful = false;
    }

    document.body.removeChild(textArea);
    this.setState({copied: successful});

    if (!this._timer) {
      this._timer = setTimeout(this.clearCopyState, CLEAR_COPIED_TEXT_MILLIS);
    }
  }

  render() {
    const {label, bsSize, intl} = this.props;

    if (!this.state.copySupported) {
      return null;
    }

    let text = label;
    let icon = "duplicate";
    if (this.state.copied) {
      text = intl.formatMessage(COPY_MESSAGES.copied);
      icon = "check";
    }

    return (
        <div style={{width: "100%"}}>
          <Button onClick={this.copy} bsSize={bsSize}><LcdIcon icon={icon}/> {text} </Button>
        </div>
    );
  }
}

export const CopyToClipboardButton = injectIntl(CopyToClipboardButtonComponent);
