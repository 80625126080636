import * as moment from "moment";
import {addLocaleData} from "react-intl";
import * as paths from "../../paths";
import {getAbsoluteBaseUrl} from "../../paths";

const getUrlQueryParameters = (defaults: { [key: string]: string }, useHash = false): { [key: string]: string } => {
  let query = window.location.search;
  if (useHash) {
    const indexOfQuestionMark = window.location.hash.indexOf("?");
    if (indexOfQuestionMark >= 0) {
      query = window.location.hash.slice(indexOfQuestionMark);
    } else {
      query = "";
    }
  }
  if (!query || query.length <= 1) {
    return defaults;
  } else {
    const params: { [key: string]: string } = {};
    for (const attrname in defaults) {
      if (defaults.hasOwnProperty(attrname)) {
        params[attrname] = defaults[attrname];
      }
    }
    query.substring(1).split("&").forEach((param) => {
      const pair = param.split("=");
      const key = pair[0];
      params[key] = pair.length > 1 ? pair[1] : "true";
    });
    return params;
  }
};

const loadScript = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.onload = () => {
      resolve();
    };
    script.onerror = reject;
    script.async = true;
    script.src = url;
    document.body.appendChild(script);
  });
};

declare const ReactIntlLocaleData: any;

export interface LocaleSetup {
  locale: string;
  messages: {
    [messageId: string]: string;
  };
}

let _lang = null;
let _locale = null;

/**
 * @returns an object with 2 properties: language and locale
 * language: full language specifier, as used by momentjs. Examples: (en-US, nl-BE, zh-CN,...).
 * locale: short code indicating the locale. Used by react-intl and for downloading translation files Examples: (en, nl, zh,...).
 */
export const getLanguageAndLocale = () => {
  if (!_lang && !_locale) {
    const languageFromUrl = getUrlQueryParameters({}, true)["lang"];
    const languageFromBrowser = navigator.language;
    _lang = languageFromUrl || languageFromBrowser || "en";
    const isFullLocaleIdentifier = _lang.indexOf("-") > 0;
    _locale = isFullLocaleIdentifier ? _lang.split("-")[0] : _lang;
  }
  return {
    language: _lang,
    locale: _locale,
  };
};

export const setupMomentIntl = () => {
  const {language} = getLanguageAndLocale();
  moment.locale(language);
};

export const setupReactIntl = (): Promise<LocaleSetup> => {
  const {locale} = getLanguageAndLocale();
  let messages = {};

  const urlToLanguageJson = getAbsoluteBaseUrl() + paths.TRANSLATIONS_PATH + "/" + locale + ".json";
  const loadMessagesJson = () => {
    return fetch(urlToLanguageJson)
        .then((response) => response.json())
        .then((responseMessages) => messages = responseMessages)
        .catch((error) => {
          //tslint:disable-next-line
          console.log("Could not fetch language file: " + urlToLanguageJson + ". Defaulting to English.");
          return {};
        });
  };

  const loadLocaleData = () => {
    return loadScript(getAbsoluteBaseUrl() + paths.TRANSLATIONS_LOCALE_DATA_PATH + "/" + locale + ".js")
        .then(() => {
          // locale data is loaded using UMD. It sets its result to the ReactIntlLocaleData global
          // (cfr. https://github.com/yahoo/react-intl/wiki#locale-data-in-browsers)
          addLocaleData(ReactIntlLocaleData[locale]);
        });
  };

  const returnLocaleAndMessages = () => {
    return {
      locale,
      messages,
    };
  };

  return loadMessagesJson()
      .then(loadLocaleData)
      .then(returnLocaleAndMessages)
      .catch(returnLocaleAndMessages);

};
