import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {moveItem} from "../common/util/ArrayUtil";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {Product} from "../products/model";
import {actions} from "./actions";
import {Service} from "./model";
import {selectors as serviceSelectors} from "./selectors";
import {ServiceProductList} from "./ServiceProductList";

interface ServiceProductsProps {
  service: Service;
  serviceProducts: Product[];

  addProductToService: (product: Product, currentProducts: Product[]) => number;
  reorderProducts: (oldIndex: number, newIndex: number, items: Product[]) => any;
  removeProductFromService: (productId: string) => any;
  loadServiceProducts: () => any;
}

class ServiceProductsComponent extends React.Component<ServiceProductsProps & WithApiProperties & InjectedIntlProps, {}> {

  componentDidMount() {
    this.props.loadServiceProducts();
  }

  render() {
    const {service, serviceProducts, removeProductFromService, addProductToService, reorderProducts} = this.props;
    if (!service) {
      return null;
    }

    return (
     <ServiceProductList items={serviceProducts || []}
                         onReorder={(oldIndex: number, newIndex: number, items: Product[]) => reorderProducts(oldIndex, newIndex, serviceProducts)}
                         onAdd={(product) => addProductToService(product, serviceProducts)}
                         onRemove={(item) => removeProductFromService(item.id)}
                         service={service}
                         serviceType={service.type}
                         readOnly={false}
                         showValidationBadge={false}
                         noLinks={false}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const serviceId = ownProps.service.id; //injected by parent
  return {
    serviceProducts: serviceSelectors.getServiceProducts(state, serviceId),
    service: serviceSelectors.getServiceById(state, serviceId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const serviceId = ownProps.service.id; //injected by parent

  return {
    loadServiceProducts: () => dispatch(actions.loadServiceProducts(serviceId)),
    addProductToService: (product, productServices) => dispatch(actions.addProductToServiceIfNoWarnings(serviceId, product.id, product.name, productServices, ownProps.intl)),
    removeProductFromService: (productId) => {
      dispatch(actions.removeProductFromService(serviceId, productId));
      },
    reorderProducts: (oldIndex, newIndex, items) => {
      const newContents = moveItem<Product>(items, oldIndex, newIndex).reverse();
      dispatch(actions.setServiceProducts(ownProps.service.id, newContents));
    },

  };
};

export const ServiceDetailProducts = injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(WithApi(ServiceProductsComponent)));
