import * as moment from "moment";
import {parse, stringify} from "query-string";
import * as React from "react";
import {Button, Form, FormControl} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {EntityType} from "../../../model";
import {getLanguageAndLocale} from "../../intl/intl";
import {LcdIcon} from "../icon/LcdIcon";
import {TextTypeahead} from "./TextTypeahead";

const MESSAGES = defineMessages({
  today: {
    id: "studio.search-form.date-picker-today-label",
    defaultMessage: "Today",
  },
});

interface SearchFormProperties {
  entityType: EntityType;
  onSearch: (fieldValues: {[fieldName: string]: string}) => Promise<void>;
  fields: SearchFormField[];
  values?: {[fieldName: string]: string};
  syncFieldsWithUrl?: boolean;
}

export type SearchFormFieldType = "text" | "typeahead-text" | "select" | "date" ;

export interface SearchFormField {
  type: SearchFormFieldType;
  name: string;
  flexGrow: number;
  defaultValue?: string;
}

export interface TextField extends SearchFormField {
  type: "text";
  placeholder?: string;
}

export interface TypeaheadTextField extends SearchFormField {
  type: "typeahead-text";
  placeholder?: string;
}

export interface SelectField extends SearchFormField {
  type: "select";
  options?: any[] | { value: any, label: any }[];
}

export interface DateField extends SearchFormField {
  type: "date";
  placeholder?: string;
}

interface SearchFormState {
  editedValues: {[fieldName: string]: string}; //field.name => value map
  isSearching: boolean;
}

class SearchFormComponent extends React.Component<SearchFormProperties & RouteComponentProps<{}> & InjectedIntlProps, SearchFormState> {

  constructor(props) {
    super(props);
    const initialState = {};
    props.fields.map((field) => {
      initialState[field.name] = field.defaultValue || "";
    });
    this.state = {editedValues: initialState, isSearching: false};
  }

  componentDidMount() {
    this.syncFromUrl(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.allValuesEqual(this.props.values, nextProps.values)) {
      this.syncToUrl(nextProps);
    }
  }

  allValuesEqual(valuesA, valuesB) {
    const keysOfA = Object.keys(valuesA);
    for (const key of keysOfA) {
      const valueEqual = valuesA[key] === valuesB[key];
      if (!valueEqual) {
        return false;
      }
    }
    return true;
  }

  syncFromUrl(props) {
    if (props.syncFieldsWithUrl) {
      const changedFields = {};
      let fieldsHaveBeenChangedFromUrl = false;
      props.fields.map((field) => {
        const valueFromUrl = parse(props.location.search)[field.name];
        const valueFromRedux = (props.values && props.values[field.name]) || "";
        if (valueFromUrl && valueFromUrl !== valueFromRedux) {
          changedFields[field.name] = valueFromUrl;
          fieldsHaveBeenChangedFromUrl = true;
        }
      });
      if (!fieldsHaveBeenChangedFromUrl) {
        this.syncToUrl(props);
      }
      this.performSearch(Object.assign({}, props.values, changedFields));
    }
  }

  syncToUrl(props) {
    if (props.syncFieldsWithUrl) {
      //strip out empty values, to keep the URL shorter
      const query = this.stripPropertiesWithEmptyStringValue(props.values);
      props.history.push({
        search: stringify(query),
      });
    }
  }

  startSearching = () => {
    return new Promise<void>((resolve) => {
      const newState = Object.assign({}, this.state, {isSearching: true});
      this.setState(newState, resolve);
    });
  }

  finishSearching = () => {
    return new Promise<void>((resolve) => {
      const newEditedValues = {};
      this.props.fields.forEach((field) => newEditedValues[field.name] = null);
      const newState = Object.assign({}, this.state, {isSearching: false, editedValues: newEditedValues});
      this.setState(newState, resolve);
    });
  }

  performSearch = (values) => {
    this.startSearching().then(() => {
      const formValues = this.stripPropertiesWithEmptyStringValue(values);
      return this.props.onSearch(formValues).then(this.finishSearching);
    }).catch(this.finishSearching);
  }

  stripPropertiesWithEmptyStringValue = (obj: any) => {
    const strippedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] && obj[key].length !== 0) {
        strippedObj[key] = obj[key];
      }
    }
    return strippedObj;
  }

  handleSubmit = (event?) => {
    if (event) {
      event.preventDefault();
    }
    const mergedValues = {};
    this.props.fields.forEach((field) => {
      mergedValues[field.name] = this.state.editedValues[field.name] !== null ? this.state.editedValues[field.name] : this.props.values[field.name];
    });
    this.performSearch(mergedValues);
  }

  render() {
    const {isSearching} = this.state;
    return (
        <Form className="searchForm" onSubmit={this.handleSubmit}>
          {this.props.fields.map((field) => {
                const stateValue = this.state.editedValues[field.name];
                const propValue = this.props.values && this.props.values[field.name];
                const value = stateValue !== null ? stateValue : propValue;
                return (<SearchFormEntry field={Object.assign({}, field)}
                                         key={field.name}
                                         value={value || ""}
                                         onChange={(newValue) => {
                                  const newValues =  Object.assign({}, this.state.editedValues, {[field.name]: newValue || ""});
                                  this.setState(Object.assign({}, this.state, {editedValues: newValues}));
                                }}
                                         entityType={this.props.entityType}
                />);
          },

          )}
          <div className="searchFormSubmitGroup" style={{flexGrow: 1}}>
            <Button className="searchFormSubmitButton"
                    type="submit"
                    disabled={isSearching}>
              <LcdIcon icon="search"/> <FormattedMessage
                id="studio.ui.search.searchform.search-button"
                defaultMessage="Search"/>
            </Button>
          </div>
        </Form>
    );
  }
}

export const SearchForm = withRouter(injectIntl(SearchFormComponent));

interface SearchFormEntryProperties {
  field: SearchFormField;
  value: any;
  onChange: (newValue: string) => void;
  entityType?: EntityType;
}

let fieldIdCounter = 0;

export class SearchFormEntryComponent extends React.Component<InjectedIntlProps & SearchFormEntryProperties, {}> {

  _fieldInputId: string; //for linking labels with input

  componentDidMount() {
    this._fieldInputId = "form-field-" + fieldIdCounter++;
  }

  renderControl() {
    const {field, value, onChange, intl} = this.props;
    const {type, name} = field;
    switch (type) {
    case "text":
      return (
          <FormControl componentClass={"input"}
                       type={"search"}
                       id={this._fieldInputId}
                       name={name}
                       placeholder={(field as TextField).placeholder}
                       value={value}
                       onChange={(event) => {
                         event.preventDefault();
                         onChange((event.target as any).value);
                       }}/>
      );
    case "typeahead-text":
      return (
          <TextTypeahead
              value={value}
              name={name}
              placeholder={(field as TypeaheadTextField).placeholder}
              onChange={onChange}
              entityType={this.props.entityType}
          />
      );
    case "select":
      const selectField: SelectField = field as SelectField;
      const {options} = selectField;
      return (
          <FormControl componentClass={"select"}
                       style={{width: "100px"}} /* keep select fixed width so searchform looks the same on all pages */
                       id={this._fieldInputId}
                       name={name}
                       value={value}
                       onChange={(event) => {
                         event.preventDefault();
                         onChange((event.target as any).value);
                       }}>
            {(options as any).map((option) => {
              if (typeof option === "string") {
                return <option value={option} key={option}>{option}</option>;
              } else {
                return <option value={option.value} key={option.value}>{option.label}</option>;
              }
            })}
          </FormControl>
      );
    case "date":
      const dateField: DateField = field as DateField;
      const date = value === "" ? null : moment(value).toDate();
      const {language} = getLanguageAndLocale();
      const today = this.props.intl.formatMessage(MESSAGES.today);
      return (
          <div className="datepicker-container">
            <ReactDatePicker selected={date}
                             locale={language}
                             onChange={(changedDate: Date) => {
                        if (changedDate) {
                          onChange(changedDate.toISOString()); //pass date back as a string, but make sure no date info is lost (use a ISO-8601 string)
                        } else {
                          onChange("");
                        }
                      }}
                             isClearable={true}
                             todayButton={today}
                             showMonthDropdown={true}
                             showYearDropdown={true}
                             scrollableYearDropdown={true}
                             className="form-control"
                             placeholderText={dateField.placeholder}
                             id={this._fieldInputId}
                             name={name}
          />
            {date === null ? <div className="calenderIconContainer"><LcdIcon icon="calendar"/></div> : null}
          </div>
      );
    default:
      throw new Error("Unknown field type: " + type);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isSelectField = this.props.field && (this.props.field as SelectField).options;
    if (isSelectField) {
      const selectFieldFromThisProps = this.props.field as SelectField;
      const selectFieldFromNextProps = nextProps.field as SelectField;
      const valuesDifferent = this.props.value !== nextProps.value;
      return valuesDifferent || selectFieldFromNextProps.options.length !== selectFieldFromThisProps.options.length;
    }
    return true;
  }

  render() {
    const {field} = this.props;
    const {flexGrow} = field;
    return (
        <div className="searchFormGroup" style={{flexGrow}}>
          {this.renderControl()}
        </div>
    );
  }
}

export const SearchFormEntry = injectIntl(SearchFormEntryComponent);
