import {History} from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import Joyride, {Locale} from "react-joyride";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {actions} from "../notification/actions";

interface UserTourState {
  tourIsWaitingForSelector: boolean;
  tourSteps: any[];
  errorOccurred: boolean;
}

export interface UserTourExternalProps {
  allTourSteps: any[];
  appLayoutRef: React.ReactInstance;
  initialTourLocation?: string;
  finalTourLocation?: string;

  tourCompleted: boolean;
  setTourCompleted: (isUserTourCompleted: boolean) => void;
  history: History;
}

export interface UserTourDispatchProps {
  showErrorNotification: (error: string) => void;
}

export type UserTourComponentProps = UserTourExternalProps & UserTourDispatchProps;

const USER_TOUR_MESSAGES = defineMessages({
  next: {id: "studio.user-tour.next", defaultMessage: "Next"},
  finish: {id: "studio.user-tour.finish", defaultMessage: "Finish"},
  skipTour: {id: "studio.user-tour.skip-tour", defaultMessage: "Skip Tour"},
});

export class UserTourComponent<CustomProps> extends React.Component<UserTourComponentProps & InjectedIntlProps & CustomProps, UserTourState> {

  constructor(props) {
    super(props);
    this.state = {
      tourIsWaitingForSelector: false,
      tourSteps: [],
      errorOccurred: false,
    };
  }

  componentDidMount() {
    if (this.props.tourCompleted === false) {
      this.initTour();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.tourCompleted === true && nextProps.tourCompleted === false) {
      this.initTour();
    }

    // Show notification if there was an error
    if (this.props.tourCompleted === false && nextProps.tourCompleted === true && this.state.errorOccurred) {
      this.props.showErrorNotification("An error occurred while running the user tour. Please contact Luciad support.");
      this.setState(Object.assign(this.state, {errorOccurred: false}));
    }
  }

  waitForSelector = (selector: string) => {
    let selectorChecks = 0;
    const maxChecks = 10;
    return new Promise<void>((resolve, reject) => {
      const checkSelector = () => {
        const domNode = ReactDOM.findDOMNode(this.props.appLayoutRef) as Element;
        const domTarget = domNode && domNode.getElementsByClassName(selector);
        if (domTarget && domTarget.length > 0) {
          resolve();
        } else {
          if (selectorChecks < maxChecks) {
            setTimeout(checkSelector, 100);
            selectorChecks++;
          } else {
            // Finish tour if next selector not found after 1 second
            this.finishTour(true);
          }
        }
      };
      checkSelector();
    });
  }

  hasNoMoreStepsAvailable = () => {
    return this.props.allTourSteps.length === this.state.tourSteps.length;
  }

  initTour = () => {
    if (this.state.tourSteps.length === 0) {
      this.addNewStep(this.props.initialTourLocation);
    }
  }

  finishTour = (errorOccurred: boolean) => {
    this.setState({
      tourIsWaitingForSelector: false,
      tourSteps: [],
      errorOccurred,
    });
    this.props.setTourCompleted(true);
    this.props.history.push(this.props.finalTourLocation ? this.props.finalTourLocation : "/userTours");
  }

  addNewStep = (nextStepLocation) => {
    // Navigate to a different page if necessary
    if (nextStepLocation) {
      this.props.history.push(nextStepLocation);
    }

    this.setState(Object.assign(this.state, {tourIsWaitingForSelector: true}));

    if (this.hasNoMoreStepsAvailable()) {
      this.finishTour(false);
    } else {
      const nextTourStep = this.props.allTourSteps[this.state.tourSteps.length];

      // Wait until next selector is rendered (Note that the leading '.' has been removed)
      this.waitForSelector(nextTourStep.selector.substr(1)).then(() => {
        this.setState({
          tourSteps: this.state.tourSteps.concat(nextTourStep),
          tourIsWaitingForSelector: false,
          errorOccurred: false,
        });
      }).catch((err) => {
        throw err;
      });
    }
  }

  onChangeTourGuideStep = (options) => {
    if (options.action === "next" && options.type === "step:after") {
      this.addNewStep(options.step.navigateToOnNext);
    } else if (options.action === "close" || options.action === "skip" || options.action === "esc") {
      this.finishTour(false);
    }
  }

  render() {
    const buttonsLabels: Locale = {
      back: "Back",
      close: "Close",
      last: this.hasNoMoreStepsAvailable() ? this.props.intl.formatMessage(USER_TOUR_MESSAGES.finish)
          : this.props.intl.formatMessage(USER_TOUR_MESSAGES.next),
      next: this.props.intl.formatMessage(USER_TOUR_MESSAGES.next),
      skip: this.props.intl.formatMessage(USER_TOUR_MESSAGES.skipTour),
    };
    const {tourCompleted} = this.props;

    if (!tourCompleted) {
      return (
          <Joyride steps={this.state.tourSteps} run={this.state.tourSteps.length > 0 && !this.state.tourIsWaitingForSelector} autoStart={true}
                   locale={buttonsLabels}
                   type="continuous" showSkipButton={true} showBackButton={false}
                   callback={this.onChangeTourGuideStep}/>
      );
    }

    return null;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showErrorNotification: (error: string) => {
      return dispatch(actions.showErrorNotification(error));
    },
  };
};

export const UserTour = withRouter(connect(null, mapDispatchToProps)(injectIntl(UserTourComponent)));
