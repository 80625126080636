import {combineReducers, Reducer} from "redux";
import {createLoadDataReducer, LoadDataState} from "../../common/util/asyncdata/reducerUtil";
import {createPagedDataReducer, PagedDataState} from "../../common/util/pagination/PaginationUtil";
import {FileInfo} from "../../data/model";
import {CompositeJob, FileInfoSearchFilter} from "../model";
import {jobReducerUtil} from "../reducerUtil";
import {importJobModuleName, preprocessJobModuleName} from "../selectors";
import {actionTypes, IMPORT_JOB_FILES_ACTION_TYPE_NAME, LOAD_JOB_DETAIL_PREFIX, LOAD_JOBS_PREFIX} from "./actions";

type FilterState = FileInfoSearchFilter;

export interface ByIdState {
  [id: string]: CompositeJob;
}

export type AllIdState = string[];

export interface JobState {
  byId: ByIdState;
  allIds: AllIdState;
  loadJobsRequest: LoadDataState<CompositeJob[]>;
  loadJobDetailRequest: LoadDataState<CompositeJob>;
  jobFilePages: PagedDataState<FileInfo>;
  filter: FilterState;
  currentJobId?: string;
}

const jobByIdReducer = (state: ByIdState = {}, action): ByIdState => {
  switch (action.type) {
  case actionTypes.LOAD_JOB_DETAIL_SUCCESS:
    return jobReducerUtil.loadJobDetailForJobByIdReducer(state, action);
  case actionTypes.LOAD_JOBS_SUCCESS:
    return jobReducerUtil.loadJobsSuccessForJobByIdReducer(state, action);
  case actionTypes.ADD_JOB:
  case actionTypes.UPDATE_JOB:
    return jobReducerUtil.updateJobActionForJobByIdReducer(state, action);
  case actionTypes.REMOVE_JOB:
    return jobReducerUtil.deleteJobForJobByIdReducer(state, action);
  case actionTypes.QUEUE_JOB:
  case actionTypes.RUN_JOB:
  case actionTypes.PROGRESS_JOB:
  case actionTypes.FINISH_JOB:
  case actionTypes.FAIL_JOB:
  case actionTypes.STOP_JOB:
    return jobReducerUtil.updateJobForJobByIdReducer(state, action);
  default:
    return state;
  }
};

const allIdReducer = (state: AllIdState = [], action): AllIdState => {
  switch (action.type) {
  case actionTypes.LOAD_JOBS_SUCCESS:
    return jobReducerUtil.loadJobsSuccessForAllIdReducer(state, action);
  case actionTypes.ADD_JOB:
    return jobReducerUtil.addJobForAllIdReducer(state, action);
  case actionTypes.REMOVE_JOB:
    return jobReducerUtil.removeJobForAllIdReducer(state, action);
  default:
    return state;
  }
};

const loadJobRequestReducer = createLoadDataReducer<CompositeJob[]>(LOAD_JOBS_PREFIX, []);
const loadJobDetailRequestReducer = createLoadDataReducer<CompositeJob>(LOAD_JOB_DETAIL_PREFIX, null);

const jobFilePagedDataReducer = createPagedDataReducer<FileInfo>(IMPORT_JOB_FILES_ACTION_TYPE_NAME);

function filterReducer(state: FilterState = {}, action) {
  switch (action.type) {
  case actionTypes.SET_IMPORT_JOB_FILTER:
    return action.payload;
  }
  return state;
}

function currentJobReducer(state: string = "", action) {
  switch (action.type) {
  case actionTypes.LOAD_JOB_DETAIL_SUCCESS:
    return action.payload.id;
  }
  return state;
}

const reducer: Reducer<JobState> = combineReducers<JobState>({
  filter: filterReducer,
  byId: jobByIdReducer,
  allIds: allIdReducer,
  loadJobsRequest: loadJobRequestReducer,
  loadJobDetailRequest: loadJobDetailRequestReducer,
  jobFilePages: jobFilePagedDataReducer,
  currentJobId: currentJobReducer,
});

const createTestState = (jobs: CompositeJob[],
                         moduleName: string = importJobModuleName,
                         filter: FileInfoSearchFilter = {}): { [moduleName: string]: JobState } => {
  const byIdState = {};
  jobs.forEach((product) => byIdState[product.id] = product);
  return {
    [moduleName]: {
      byId: byIdState,
      allIds: jobs.map((job) => job.id),
      loadJobsRequest: {
        isLoading: false,
        isFinished: true,
        payload: jobs,
        error: false,
      },
      loadJobDetailRequest: {
        isLoading: false,
        isFinished: true,
        payload: null,
        error: false,
      },
      filter,
      jobFilePages: {
        data: null,
        pages: {},
        pageSize: null,
        maxPageInfo: null,
      },
      currentJobId: jobs.length > 0 ? jobs[0].id : "1",
    },
  };
};

export {
  reducer,
  importJobModuleName,
  preprocessJobModuleName,
  createTestState,
};
