import * as React from "react";
import {FormControl} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntl, InjectedIntlProps, injectIntl} from "react-intl";
import {Link, Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailMeta} from "../common/ui/detailsection/DetailMeta";
import {createCommonMetadataFields, DetailView, FieldInfo, Formats} from "../common/ui/detailsection/DetailView";
import {SectionHeader} from "../common/ui/detailsection/SectionHeader";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {Previewer} from "../common/ui/riamap/Previewer";
import {Logger} from "../common/util/Logger";
import {getParameterFromOwnProps} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import * as paths from "../paths";
import {DeleteStyleButton} from "./DeleteStyleButton";
import {Style} from "./model";
import {RefreshStyleButton} from "./RefreshStyleButton";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {PreviewMetadata} from "../common/ui/riamap/model";

type StyleDetailPageProps = InjectedIntl & RouteComponentProps<{ id: string }> & WithApiProperties;

interface StyleDetailState {
  style: Style;
  deleted: boolean;
  loading: boolean;
}

const STYLE_DETAIL_PAGE_MESSAGES = defineMessages({
  ID: {
    id: "studio.style-detail-page.detail.id",
    defaultMessage: "ID",
  },
  FileSize: {
    id: "studio.style-detail-page.file-size",
    defaultMessage: "File Size",
  },
  Type: {
    id: "studio.style-detail-page.type",
    defaultMessage: "Type",
  },
  styleLoadError: {
    id: "studio.style-detail-page.load-error",
    defaultMessage: "Error occurred while loading style",
  },
  styleUpdateError: {
    id: "studio.style-detail-page.update-error",
    defaultMessage: "Error occurred while updating style",
  },
});

export class StyleDetailPageComponent extends React.Component<StyleDetailPageProps & InjectedIntlProps, StyleDetailState> {
  _logger = Logger.getLogger("styles.StyleDetailPage");

  constructor(props) {
    super(props);
    this.state = {style: null, deleted: false, loading: false};
  }

  componentDidMount() {
    this._getStyle(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (getParameterFromOwnProps(this.props, "id") !== getParameterFromOwnProps(props, "id")) {
      this._getStyle(props);
    }
  }

  _getStyle(props) {
    this.setState({loading: true});
    const styleId = getParameterFromOwnProps(props, "id"); //maybe injected by withRouter
    this.props.api.getStyleById(styleId)
        .then((style) => this.addBoundsToStyle(style))
        .then((styleWithBounds) => {
          this.setState({style: styleWithBounds, loading: false});
        }).catch((error) => {
          this._logger.error("Error occurred while loading style", error);
          this.setState({loading: false});
        });
  }

  addBoundsToStyle = (style: Style) => {
    const styleWithoutBounds = style;
    return this.props.api.getStyleBoundsByType(style.type).then((bounds) => {
      return Object.assign({}, styleWithoutBounds, {wgs84Bounds: bounds});
    });
  }

  getMasterPane = () => {
    const {style, loading} = this.state;
    const {intl} = this.props;
    if (loading) {
      return <p><FormattedMessage id="studio.style-detail-page.loading" defaultMessage="Loading..."/></p>;
    } else {
      if (!style) {
        return <div>
          <h2><FormattedMessage
              id="studio.style-detail-page.id-error"
              defaultMessage="Could not find style with id: {id}"
              values={{
                id: this.props.match.params.id,
              }}/></h2>
          <p><Link to="/styles"><FormattedMessage
              id="studio.style-detail-page.error-go-back"
              defaultMessage="Go back to styles overview"/></Link></p>
        </div>;
      }

      const fields: FieldInfo[] = ([
        {
          key: "Id",
          name: intl.formatMessage(STYLE_DETAIL_PAGE_MESSAGES.ID),
          value: style.id,
        },
        {
          key: "File Size",
          name: intl.formatMessage(STYLE_DETAIL_PAGE_MESSAGES.FileSize),
          value: style.fileMetadata.size,
          format: Formats.FILESIZE,
        },
        {
          key: "Type",
          name: intl.formatMessage(STYLE_DETAIL_PAGE_MESSAGES.Type),
          value: style.type.toString(),
        },
      ] as FieldInfo[]).concat(createCommonMetadataFields(style, intl));

      return (
        <div>
          <DetailHeader title={style.title} buttons={[
            <ActionBar key="actionbar">
              <RefreshStyleButton key="refresh-style-button" style={style} onRefreshSuccess={this.updateStyle} onRefreshError={Function.prototype}/>
              <DeleteStyleButton key="delete-style-button" disabled={false} styles={[style]} onDeleteSuccess={this.setDeleted} onDeleteError={Function.prototype}
                                 onDeleteWarnings={Function.prototype} onCloseWarnings={this.setDeleted} detail={true}/>
            </ActionBar>
            ]}/>
          <SectionHeader>
            <FormattedMessage
                id="studio.style-detail-page.metadata-header"
                defaultMessage="Metadata"/>
          </SectionHeader>

          <DetailMeta metadata={style}
                      updateEntity={(newMetadata) => {
                        // remove bounds because it doesn't exist in the server Style
                        const styleToBeUpdated = Object.assign({}, style, {...newMetadata, wgs84Bounds: undefined});
                        this.props.api.updateStyle(styleToBeUpdated)
                            .then((updatedStyle) => this.addBoundsToStyle(updatedStyle))
                            .then((styleWithBounds) => {
                              this.setState({style: styleWithBounds});
                            })
                            .catch((error) => {
                              this._logger.error("Error occurred while updating style", error);
                            });
                      }}
          />

          <DetailView fields={fields}/>

          <SectionHeader>
            <FormattedMessage
                id="studio.style-detail-page.sld-header"
                defaultMessage="SLD"/>
          </SectionHeader>
          <FormControl componentClass="textarea" disabled
                       value={style.content}
                       style={{height: 300}}/>
        </div>
      );
    }
  }

  getDetailPane = () => {
    const {style} = this.state;
    return <Previewer wmsBaseUrl={paths.PREVIEW_STYLE_PATH}
                      dataToVisualize={style ? [style] : []}
                      shouldFit={true}
                      getPreviewMetadataFunction={(item: Style) => this.props.api.canPreviewStyleType(item.type).then((canPreview) => {
                        return {
                          previewable: canPreview,
                          shouldForceToPreview: false,
                          georeferenced: true,
                          empty: false,
                        };
                      })}
    />;
  }

  setDeleted = () =>  {
    this.setState(Object.assign({}, this.state, {deleted: true}));
  }

  updateStyle = (updatedStyle: Style) => {
    this.setState(Object.assign({}, this.state, {style: updatedStyle}));
  }

  render() {
    if (this.state.deleted) {
      return <Redirect to="/styles"/>;
    } else {
      return (
          <div>
            <MasterDetailLayout masterPane={this.getMasterPane()}
                                detailPane={this.getDetailPane()}
                                evenSplit={true}
            />
          </div>
      );
    }
  }
}

export const StyleDetailPage = withRouter(WithApi(injectIntl(StyleDetailPageComponent)) as React.ComponentClass<any>);
