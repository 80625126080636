import * as React from "react";
import * as Button from "react-bootstrap/lib/Button";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {hasSubmitSucceeded, isSubmitting} from "redux-form";

interface CloseButtonProps {
  onClick?: (canceled: boolean) => void;
}

interface CloseButtonComponentProps extends CloseButtonProps {
  submitting: boolean;
  submitSucceeded: boolean;
}

export const createCloseButton = (reduxFormName: string) => {

  class CloseButtonComponent extends React.Component<CloseButtonComponentProps, {}> {

    _isListeningToWindow: boolean = false;

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.submitSucceeded && !this._isListeningToWindow) {
        this.installEnterKeyListeners();
      } else if (!nextProps.submitSucceeded && this._isListeningToWindow) {
        this.removeInstallEnterKeyListeners();
      }
    }

    componentWillUnmount() {
      if (this._isListeningToWindow) {
        this.removeInstallEnterKeyListeners();
      }
    }

    handleKeyPressed = (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === "Escape") {
        if (this.props.onClick) {
          this.props.onClick(this.props.submitSucceeded);
        }
      }
    }

    installEnterKeyListeners = () => {
      document.addEventListener("keyup", this.handleKeyPressed);
      this._isListeningToWindow = true;
    }

    removeInstallEnterKeyListeners = () => {
      document.removeEventListener("keyup", this.handleKeyPressed);
      this._isListeningToWindow = false;
    }

    handleClick = (e: React.MouseEvent<Button>) => {
      this.props.onClick(!this.props.submitSucceeded);
    }

    render() {
      const {submitting, submitSucceeded} = this.props;
      return (
          <Button disabled={submitting} onClick={this.handleClick}>
            {submitSucceeded ? (
                <FormattedMessage id="studio.ui.form-buttons.close" defaultMessage="Close"/>
            ) : (
                <FormattedMessage id="studio.ui.form-buttons.cancel" defaultMessage="Cancel"/>
            )}
          </Button>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      submitting: isSubmitting(reduxFormName)(state),
      submitSucceeded: hasSubmitSucceeded(reduxFormName)(state),
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      onClick: ownProps.onClick,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(CloseButtonComponent);

};
