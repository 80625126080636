import {BaseCellProps} from "fixed-data-table-2";
import {InjectedIntl} from "react-intl";
import {DateUtil} from "../../util/DateUtil";
import * as moment from "moment";

export interface ControlRoomColumn<T> {
  header: React.ReactElement<BaseCellProps> | string | number;
  cellContent: (entry: T, index: number, selected: boolean,
                data: T[], selectedRowIndices: number[]) => React.ReactElement<any> | string | number;
  cellTooltip?: (entry: T, intl?: InjectedIntl, canClickRow?: boolean) => string;
  columnWidth?: ColumnWidth;
}

export class ColumnWidthType {
  static PIXELS: ColumnWidthType = new ColumnWidthType("PIXELS");
  static FLEX: ColumnWidthType = new ColumnWidthType("FLEX");

  private constructor(public value: string) {
  }
}

const PADDING = 2 * 8;

const estimateColumnWidth = (text: string) => {
  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  const fontWeight = 300;
  const fontSize = 13;
  const font = fontWeight + " " + fontSize + 'px "Open Sans", sans-serif';
  ctx.font = font;
  const result = ctx.measureText(text);
  /* DEBUG
  canvas.width = Math.ceil(result.width);
  canvas.height = fontSize;
  ctx.font = font; // resizing the canvas resets properties, need to re-set the font property
  ctx.fillStyle = "white";
  ctx.textAlign = "left";
  ctx.textBaseline = "bottom";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = "black";
  ctx.fillText(text, 0, fontSize);
  //tslint:disable-next-line:no-console
  console.log(canvas.toDataURL("image/png"));
  //tslint:disable-next-line:no-console
  console.log("column width: " + (result.width + PADDING));
  //*/
  return result.width + PADDING;
};

const estimateDateTimeColumnWidth = () => {
  const formattedDateStr = DateUtil.formatDateTime(moment("2399-12-29T23:39:00")); // take a date with many chars (29/12/2399 23:39)
  return estimateColumnWidth(formattedDateStr);
};

/**
 * Column widths in the ControlRoomTable work as follows:
 * - Fixed column widths (those with a set amount of pixels) are sized
 * - Then, flex columns take up the remaining width. The values are relative weights.
 *   A column with a flex weight of 2 will be twice as wide as a column with flex weight 1.
 *   Flex columns have a minimum width of ColumnWidth.MIN_FLEX_COL_WIDTH.
 */
export class ColumnWidth {
  static ID_COLUMN_WIDTH = ColumnWidth.pixels(58); //fits a 5 digit ID
  static DATETIME_COLUMN_WIDTH = ColumnWidth.pixels(estimateDateTimeColumnWidth());
  static USERNAME_COLUMN_WIDTH = ColumnWidth.pixels(120);
  static TYPE_COLUMN_WIDTH = ColumnWidth.pixels(120);
  static STARTSTOP_BUTTON_COLUMN_WIDTH = ColumnWidth.pixels(100); //fits a start/stop button, cfr JobsList and ServiceList
  static REMOVE_COLUMN_WIDTH = ColumnWidth.pixels(75); //fits a remove button + the remove header
  static MIN_FLEX_COL_WIDTH = 100; //in pixels

  static pixels(value: number) {
    return new ColumnWidth(ColumnWidthType.PIXELS, value);
  }
  static flex(value: number) {
    return new ColumnWidth(ColumnWidthType.FLEX, value);
  }

  private constructor(public type: ColumnWidthType, public value: number) {}
}

export interface ColumnsDescription<T> {
  [columnID: string]: ControlRoomColumn<T>;
}

export interface ColumnFactory<T> {
  getInitialColumnIDs(): string[];
  getInitialColumnWidth(columnID: string): ColumnWidth;
  getAvailableColumnIDs(): string[];
  createColumn(columnID: string): ControlRoomColumn<T>;
}

/**
 * Column factory implementation that is used by most ControlRoomTable implementations
 */
export class BasicColumnFactory<T> implements ColumnFactory<T> {
  columns: ColumnsDescription<T> = {};

  constructor(columns: ColumnsDescription<T>) {
    this.columns = columns;
  }

  getInitialColumnIDs(): string[] {
    return this.getAvailableColumnIDs();
  }

  getInitialColumnWidth(columnID: string) {
    return this.columns[columnID].columnWidth || ColumnWidth.pixels(200);
  }

  getAvailableColumnIDs(): string[] {
    const availableColumnIDs = [];
    for (const prop in this.columns) {
      if (this.columns.hasOwnProperty(prop)) {
        availableColumnIDs.push(prop);
      }
    }
    return availableColumnIDs;
  }

  createColumn(columnID: string): ControlRoomColumn<T> {
    return this.columns[columnID];
  }
}
