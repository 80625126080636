import * as React from "react";
import {Alert, Button, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Action, Dispatch} from "redux";
import {
  Field,
  Fields,
  formValueSelector,
  hasSubmitFailed,
  hasSubmitSucceeded,
  isSubmitting,
  reduxForm,
  SubmissionError,
  submit,
} from "redux-form";
import {InjectedFormProps} from "redux-form/lib/reduxForm";
import {DetailViewRow} from "../common/ui/detailsection/DetailView";
import {createCloseButton} from "../common/ui/FormButtons";
import {diffArray, moveItem, removeItem} from "../common/util/ArrayUtil";
import {Logger} from "../common/util/Logger";
import {calculateDimensionBasedOnRowsBeforeScroll} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {ImportedData} from "../data/model";
import {actions} from "./actions";
import {Product, ProductType, StyledData, toProductLink} from "./model";
import {PRODUCT_DATA_LIST_ROW_HEIGHT, ProductContentListPresentation} from "./ProductContentList";

// Used to "catch" newly created product for linking, which happens while submitting the form.
let _newProduct = null;

interface CreateProductFormData {
  productTitle: string;
  productName: string;
  productContent: StyledData[];
}

interface CreateProductFormOwnProps {
  initialData?: ImportedData[];
}

type CreateProductFormProps = CreateProductFormOwnProps & InjectedIntlProps & WithApiProperties;

const LABEL_COL_WIDTH = 2;

// strip spaces, convert to lowercase and strip symbols
const toSafeName = (text) => text.replace(/\s/g, "_").toLowerCase().replace(/[^\s\w\-_]/g, "");
const canAutoFill = (field) => !field.meta.touched;

const submitCreateProductForm = (values: CreateProductFormData, dispatch) => {
  const product: Product = {
    id: "-1", // temporary product id
    title: values.productTitle,
    abstractText: "",
    name: values.productName,
    contents: values.productContent || [],
    type: ProductType.EMPTY,
  };

  let resolveFunc, rejectFunc;
  const promise = new Promise((resolve, reject) => {
    resolveFunc = resolve;
    rejectFunc = reject;
  });

  dispatch(actions.createProduct(product))
      .then((newProduct) => {
        _newProduct = newProduct;
        return resolveFunc();
      })
      .catch((error: any) => {
        const errorMessage = (error.response && error.response.data &&
                              (error.response.data.message || error.response.data.details) ||
                              error.message);
        rejectFunc(new SubmissionError({_error: errorMessage}));
      });
  return promise;
};

const FIELD_PRODUCT_TITLE = "productTitle";
const FIELD_PRODUCT_NAME = "productName";

const PRODUCT_FORM_MESSAGES = defineMessages({
  productTitle: {id: "studio.products.create-product-form.title", defaultMessage: "Product title"},
  productName: {id: "studio.products.create-product-form.name", defaultMessage: "Product name"},
  productTitlePlaceholder: {
    id: "studio.products.create-product-form.title-placeholder",
    defaultMessage: "Enter product title",
  },
  styledData: {id: "studio.products.create-product-form.styled-data", defaultMessage: "Styled Data"},
  productTitleMore: {
    id: "studio.products.create-product-form.title.more",
    defaultMessage: "{title} (+{count} more)",
  },
});

const CreateProductFormInternal = class extends React.Component<CreateProductFormProps & InjectedFormProps<CreateProductFormData, CreateProductFormProps>> {
  _logger: Logger = Logger.getLogger("products.CreateProductForm");

  // temporary ID's counter - when submitted, server will assign global ID's to saved styled-products.
  tempId = -1;

  componentDidMount() {
    _newProduct = null;
    const {initialData = [], intl} = this.props;
    const productContent = initialData.map(
        (importedData) => {
          return {
            id: importedData.id,
            productId: null,
            data: importedData.id,
            dataTitle: importedData.title,
            style: importedData.defaultStyleId,
            visible: true,
          };
        },
    );

    let productTitle = "";
    const productName = "";
    if (initialData.length > 0) {
      productTitle = initialData[0].title;
      if (initialData.length > 1) {
        productTitle = intl.formatMessage(PRODUCT_FORM_MESSAGES.productTitleMore, {title: productTitle, count: initialData.length - 1});
      }
    }
    this.initializeWithStyle(productTitle, productName, productContent);
  }

  // Updates raw StyledData with style name and initializes the form data.
  initializeWithStyle = (productTitle, productName, rawData: StyledData[]) => {
    const promiseForStyledData = rawData.map((aStyledData) => {
      return !aStyledData.style ? Promise.resolve(aStyledData) : this.fetchStyleTitle(aStyledData.style).then((title) => {
        aStyledData.styleTitle = title;
        return aStyledData;
      });
    });

    Promise.all(promiseForStyledData).then(
        (productContent) => this.props.initialize({productTitle, productName, productContent}),
    ).catch((error) => this._logger.error("Error occurred while loading styled data.", error));
  }

  applyTemporaryId = (styledData: StyledData) => {
    styledData.id = (this.tempId--).toString();
    return styledData;
  }

  handleTitleChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.productTitle.input.onChange(event);

    if (canAutoFill(fields.productTitle)) {
      const serviceTitle = event.target.value;
      const derivedServiceName = toSafeName(serviceTitle);
      this.props.change(FIELD_PRODUCT_NAME, derivedServiceName);
    }
  }

  doAddNewItem = (fieldInput, newItem) => {
    this.applyTemporaryId(newItem);
    if (newItem.styleId) {
      this.fetchStyleTitle(newItem.styleId).then((title) => {
        newItem.style = {title};
        this.addToProductField(fieldInput, newItem);
      }).catch((error) => {
        this._logger.error("Error occurred while retrieving name of style with id " + newItem.styleId, error);
      });
    } else {
      this.addToProductField(fieldInput, newItem);
    }
  }

  addToProductField = (fieldInput, newItem) => fieldInput.onChange([newItem, ...fieldInput.value]);

  fetchStyleTitle = (styleId) => this.props.api.getStyleById(styleId).then((style) => style.title);

  renderProductContentList = (field) => {
    const fieldInput: any = field.input;
    return (
        <DetailViewRow name={this.props.intl.formatMessage(PRODUCT_FORM_MESSAGES.styledData)}>
          <ProductContentListPresentation
              items={fieldInput.value}
              onReorder={(oldIndex, newIndex, items) => fieldInput.onChange(moveItem(items, oldIndex, newIndex))}
              onAdd={(newItem) => this.doAddNewItem(fieldInput, newItem)}
              onRemove={(item, index) => fieldInput.onChange(removeItem(fieldInput.value, index))}
              onChange={(changedItem, index) => {
                const newContent = [...fieldInput.value];
                newContent[index] = changedItem;
                fieldInput.onChange(newContent);
              }}
              onBulkChange={(changedContent, changedIndices) => {
                const newContent = [...fieldInput.value];
                for (let i = 0; i < changedIndices.length; i++) {
                  newContent[changedIndices[i]] = changedContent[i];
                }
                fieldInput.onChange(newContent);
                return Promise.resolve();
              }}
              onBulkRemove={(removedContent) => {
                const newContent = diffArray(fieldInput.value, removedContent);
                fieldInput.onChange(newContent);
                return Promise.resolve();
              }}
              noLinks
              withVisibilityToggle={false}
              calculateDimensions={calculateDimensionBasedOnRowsBeforeScroll(5, PRODUCT_DATA_LIST_ROW_HEIGHT)}
          />
        </DetailViewRow>
    );
  }

  confirmationComponent = (newProduct: Product) => {
    return (
        <div>
          <h2><FormattedMessage id="studio.products.create-product-form.product-created"
                                defaultMessage="Product successfully created!"/></h2>
          <Link to={toProductLink(newProduct)} className="button-link">
            <Button bsStyle="info">
              <span>{<FormattedMessage id="studio.products.create-product-form.go-to-product"
                                                      defaultMessage="Go To Product {newProduct}"
                                                      values={{newProduct: newProduct.title}}/>}</span>
            </Button>
          </Link>
        </div>
    );
  }

  render() {
    const error = this.props.submitFailed ? (
        <Alert bsStyle="danger">
          <strong><FormattedMessage
              id="studio.create-product-form.submit-failed"
              defaultMessage="Product creation failed:"
          /></strong> {this.props.error}
        </Alert>
    ) : null;

    if (this.props.submitting) {
      return (
          <div className="form-message">
            <h1><FormattedMessage id="studio.products.create-product-form.creating-product"
                                  defaultMessage="Creating Product..."/></h1>
          </div>
      );
    }
    if (this.props.submitSucceeded) {
      return this.confirmationComponent(_newProduct);
    }
    return (
        <Form horizontal onSubmit={this.props.handleSubmit}>
          {error}
          <Fields names={[FIELD_PRODUCT_TITLE, FIELD_PRODUCT_NAME]}
                  component={this.renderFields}/>
          <Field name="productContent" component={this.renderProductContentList}/>
        </Form>
    );
  }

  renderFields = (fields) => {
    const fProductTitle = fields[FIELD_PRODUCT_TITLE];
    const fProductName = fields[FIELD_PRODUCT_NAME];

    const {autofilled, error} = fProductName.meta;
    const autoFillClass = autofilled ? "autofill" : "";
    return (
        <div>
          <FormGroup controlId={fProductTitle.input.name}
                     validationState={fProductTitle.meta.error ? "error" : null}>
            <Col sm={LABEL_COL_WIDTH}>
              <ControlLabel><FormattedMessage id="studio.services.create-product-form.product-title"
                                              defaultMessage="Product title"/></ControlLabel>
            </Col>
            <Col sm={12 - LABEL_COL_WIDTH}>
              <FormControl type="text" {...fProductTitle.input}
                           onChange={this.handleTitleChange.bind(this, fields)}/>
            </Col>
          </FormGroup>
          <FormGroup controlId={fProductName.input.name} validationState={error ? "error" : null}>
            <Col sm={LABEL_COL_WIDTH}>
              <ControlLabel><FormattedMessage id="studio.services.create-product-form.product-name"
                                              defaultMessage="Product name"/></ControlLabel>
            </Col>
            <Col sm={12 - LABEL_COL_WIDTH}>
              <FormControl type="text" {...fProductName.input} className={autoFillClass}/>
              {fProductName.meta.error && <HelpBlock>{fProductName.meta.error}</HelpBlock>}
            </Col>
          </FormGroup>
        </div>
    );
  }
};

const validateCreateProductForm = (values) => {
  const errors: any = {};
  if (!values[FIELD_PRODUCT_TITLE]) {
    errors[FIELD_PRODUCT_TITLE] = <FormattedMessage
        id="studio.create-product-form.title-error"
        defaultMessage="required"
    />;
  }
  return errors;
};

const FORM_NAME = "createProductForm";
const formConfig = {
  form: FORM_NAME,
  onSubmit: submitCreateProductForm,
  validate: validateCreateProductForm,
};

const CreateProductForm = reduxForm<CreateProductFormData, CreateProductFormOwnProps>(formConfig)(WithApi(injectIntl(CreateProductFormInternal)));

interface CreateProductFormSubmitButtonProps {
  dispatch: Dispatch<Action>;
  submitting: boolean;
  submitSucceeded: boolean;
  submitFailed: boolean;
  productTitle: string;
}

class CreateProductFormSubmitButtonComponent extends React.Component<CreateProductFormSubmitButtonProps, {}> {
  render() {
    const {dispatch, submitting, submitSucceeded, productTitle} = this.props;
    if (submitting || submitSucceeded) {
      return null;
    }
    const disabled = (!productTitle || productTitle.trim().length === 0);
    return (
        <Button disabled={disabled} onClick={() => dispatch(submit(FORM_NAME))}>
          <FormattedMessage id="studio.products.create-product-form.create-product"
                            defaultMessage="Create Product"/>
        </Button>
    );
  }
}

const CreateProductFormSubmitButton = connect((state) => {
  return {
    submitting: isSubmitting(FORM_NAME)(state),
    submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
    submitFailed: hasSubmitFailed(FORM_NAME)(state),
    productTitle: formValueSelector(FORM_NAME)(state, "productTitle"),
  };
})(CreateProductFormSubmitButtonComponent);

const CloseCreateProductFormButton = createCloseButton(FORM_NAME);

export {
  CreateProductForm,
  CreateProductFormSubmitButton,
  CloseCreateProductFormButton,
  FORM_NAME,
};
