import * as React from "react";
import {Button, Col, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import {Alert} from "react-bootstrap/lib";
import * as Badge from "react-bootstrap/lib/Badge";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {DefaultReorderControl} from "../common/ui/reorder/DefaultReorderControl";
import {InjectedReorderableListProps, ReorderableList} from "../common/ui/reorder/ReorderableList";
import {ColumnFactory, ColumnWidth, ControlRoomColumn} from "../common/ui/table/ControlRoomColumns";
import {ControlRoomTable, ControlRoomTableProperties} from "../common/ui/table/ControlRoomTable";
import {productValidation, ValidationResult} from "../common/ui/validation/ProductValidation";
import {getConcatenatedValidationMessages} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {Identifiable} from "../model";
import {PendingProductForService, Product, ProductFilter, toProductLink} from "../products/model";
import {ProductTypeahead} from "../products/ProductTypeahead";
import {CopyToClipboardButton} from "./fileserver/CopyToClipboardButton";
import {getFileDownloadURL, getProductBrowseURL} from "./fileserver/Util";
import {Service, ServiceType} from "./model";
import InjectedIntl = ReactIntl.InjectedIntl;

interface WrapperProps {
  service?: Service;
  serviceType?: ServiceType;
  calculateDimensions?: (parent: HTMLElement) => {};
}

interface WrapperState {
  productToAdd: PendingProductForService;
  validation: ValidationResult;
  selectedIndices: number[];
}

const DEFAULT_STATE: WrapperState = {
  productToAdd: null,
  selectedIndices: [],
  validation: null,
};

const SERVICE_PRODUCT_MESSAGES = defineMessages({
  placeholder: {
    id: "studio.services.product-list.add-placeholder", defaultMessage: "Search for a product to add",
  },
  nameTooltip: {
    id: "studio.services.product-list.name.tooltip", defaultMessage: "Go to product {name}",
  },
  removeTooltip: {
    id: "studio.services.product-list.remove.tooltip", defaultMessage: "Remove product",
  },
});

type ColumnEntry = ControlRoomColumn<PendingProductForService>;

class ServiceContentColumnFactory implements ColumnFactory<Product> {

  _removeItem: (item: Product, index: number) => void;
  _moveSelection: (toIdx: number) => void;
  _service: Service;
  _serviceType: ServiceType;
  _readOnly: boolean;
  _noLinks: boolean;
  _showValidationBadge: boolean;
  _intl: InjectedIntl;

  columns: { [columnID: string]: ColumnEntry } = {};

  constructor(removeItem: ((item: Product, index: number) => void),
              moveSelection: (toIdx: number) => void,
              service: Service,
              serviceType: ServiceType,
              readOnly: boolean,
              noLinks: boolean,
              showValidationBadge: boolean,
              intl: InjectedIntl,
  ) {
    this._removeItem = removeItem;
    this._moveSelection = moveSelection;
    this._service = service;
    this._serviceType = serviceType;
    this._readOnly = readOnly;
    this._noLinks = noLinks;
    this._showValidationBadge = showValidationBadge;
    this._intl = intl;

    this.columns["product"] = {
      header: <FormattedMessage id="studio.services.product-list.title.header" defaultMessage="Title"/>,
      cellContent: (product) => {
        const productName = this.getProductName(product);
        if (this._showValidationBadge && product.pendingServiceType) {
          const validationResult = product.validationByServiceType[product.pendingServiceType];
          const severity = productValidation.getState(validationResult);
          const badgeClassName = "product-validation-badge-" + severity;
          const validationEl = <Badge pullRight className={badgeClassName}>
                                 {productValidation.getReason(validationResult)}
                               </Badge>;
          return (noLinks) ? <span> {productName} {validationEl}</span> :
                 <Link to={toProductLink(product)}> {productName} </Link>;
        }
        if (!this._noLinks) {
          return <Link to={`/products/${product.id}`}>{productName}</Link>;
        } else {
          return productName;
        }
      },
      cellTooltip: (product) => {
        if (!this._noLinks) {
          return this._intl.formatMessage(SERVICE_PRODUCT_MESSAGES.nameTooltip, {name: this.getProductName(product)});
        } else {
          return "";
        }
      },
      columnWidth: ColumnWidth.flex(2),
    };

    if (this._serviceType === ServiceType.FILE_SERVER && !this._noLinks) {
      this.columns["copyUrl"] = {
        header: "", //Since we are using a button, we don't want a header.
        cellContent: (product) => {
          const url = getFileDownloadURL(this._service, product);
          return <CopyToClipboardButton text={url}
                                        label={<FormattedMessage id="studio.services.fileserver.copy-url-button"
                                                                 defaultMessage="Copy URL"/>} bsSize="xsmall"/>;
        },
        columnWidth: ColumnWidth.pixels(120),
      };
      this.columns["browseUrl"] = {
        header: "", //Since we are using a button, we don't want a header.
        cellContent: (product) => {
          const url = getProductBrowseURL(this._service, product);
          return <Button onClick={(event) => location.href = url} bsSize="xsmall">
            <LcdIcon icon="list-alt"/> <FormattedMessage
              id="studio.services.fileserver.product-item.browse"
              defaultMessage="Browse"/>
          </Button>;
        },
        columnWidth: ColumnWidth.pixels(100),
      };
    }

    if (!this._readOnly && this._serviceType !== ServiceType.FILE_SERVER as string) {
      this.columns["move"] = {
        header: <FormattedMessage id="studio.services.product-list.move.header" defaultMessage="Moves"/>,
        cellContent: (product, index, selected, allData, selectedIndices) => {
          if (selected && selectedIndices.length === 1) {
            return (
                <DefaultReorderControl
                    moveSelection={this._moveSelection}
                    selectedIndex={index}
                    maxIndex={allData.length - 1}
                />
            );
          }
          return null;
        },
        columnWidth: ColumnWidth.pixels(70),
      };
    }

    if (!this._readOnly) {
      this.columns["remove"] = {
        header: <FormattedMessage id="studio.services.product-list.remove.header" defaultMessage="Remove"/>,
        cellContent: (product, index) => {
          return (<Button className="btn-icon" style={{backgroundColor: "transparent", color: "currentColor"}} onClick={(event) => {
            this._removeItem(product, index);
            event.stopPropagation(); //avoid that ControlRoomTable's onRowClick gets called
          }}><LcdIcon icon={"delete"}/></Button>);
        },
        cellTooltip: (product) => this._intl.formatMessage(SERVICE_PRODUCT_MESSAGES.removeTooltip),
        columnWidth: ColumnWidth.pixels(65),
      };
    }
  }

  getProductName(product: Product) {
    return product.title;
  }

  getInitialColumnIDs(): string[] {
    return this.getAvailableColumnIDs();
  }

  getInitialColumnWidth(columnID: string): ColumnWidth {
    return this.columns[columnID].columnWidth || ColumnWidth.pixels(200);
  }

  getAvailableColumnIDs(): string[] {
    const availableColumnIDs = [];
    for (const prop in this.columns) {
      if (this.columns.hasOwnProperty(prop)) {
        availableColumnIDs.push(prop);
      }
    }
    return availableColumnIDs;
  }

  createColumn(columnID: string): ControlRoomColumn<Product> {
    return this.columns[columnID];
  }
}

class ServiceProductWrapper extends React.Component<InjectedReorderableListProps<Product> & WrapperProps & WithApiProperties & InjectedIntlProps, WrapperState> {

  ServiceContentListTable: React.ComponentClass<ControlRoomTableProperties<Product>>;

  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;

    const moveSelection = (toIdx) => {
      props.moveSelection(toIdx);
      this.setState(Object.assign({}, this.state, {selectedIndices: [toIdx]}));
    };
    this.ServiceContentListTable = ControlRoomTable(
        new ServiceContentColumnFactory(
            props.removeItem, moveSelection, props.service, props.serviceType, props.readOnly, props.noLinks, props.showValidationBadge,
            props.intl,
        ),
        props.calculateDimensions,
    );
  }

  addProductToService = () => {
    this.props.addItem(this.state.productToAdd);
    this.setState(DEFAULT_STATE);
  }

  handleProductSelect = (newItem) => {
    if (newItem) {
      this.setState({validation: null});
      const {api, service} = this.props;

      if (service && newItem && service.id && newItem.id) {
        api.validateProduct(service.id, newItem.id).then((apiValidationResult: ValidationResult) => {
          const hasWarnings = apiValidationResult.errorMessages.length > 0 ||
                              apiValidationResult.warningMessages.length > 0;

          if (hasWarnings) {
            this.setState({validation: apiValidationResult});
          } else {
            this.setState({productToAdd: newItem}, this.addProductToService);
          }
        });
      } else {
        this.setState({productToAdd: newItem}, this.addProductToService);
      }
    }
  }

  acceptProductSuggestion = (product: Identifiable) => {
    const {items, validateItemToAdd} = this.props;
    if (items.some((item) => (item.id === product.id))) {
      return false;
    }
    if (validateItemToAdd) {
      return validateItemToAdd(product);
    }
    return true;
  }

  renderErrors = () => {
    const {validation} = this.state;
    if (validation && validation.errorMessages.length > 0) {
      const errorMessage = getConcatenatedValidationMessages(validation);
      return <Alert bsStyle="danger">{errorMessage}</Alert>;
    }
  }

  render() {
    const {items, readOnly, serviceType} = this.props;
    const {productToAdd, selectedIndices} = this.state;

    if (!productToAdd && !serviceType) {
      return null;
    }

    const validationResult = productToAdd && productValidation.fetchServiceValidation(productToAdd, serviceType);
    const feedbackMsg = productToAdd && productValidation.getReason(validationResult);

    const validationState = productToAdd ? productValidation.getState(validationResult) : null;

    const productFilter: ProductFilter = {
      serviceType: this.props.serviceType,
    };
    return (
        <div className="serviceProductList">

          {this.renderErrors()}

          {!readOnly && (
              <Row>
                <Col sm={12}>
                  <FormGroup validationState={validationState}>
                    <ProductTypeahead
                        value={productToAdd}
                        onChange={this.handleProductSelect}
                        acceptSuggestion={this.acceptProductSuggestion}
                        placeholder={this.props.intl.formatMessage(SERVICE_PRODUCT_MESSAGES.placeholder)}
                        productFilter={productFilter}
                    />
                    <FormControl.Feedback/>
                    <HelpBlock>{feedbackMsg}</HelpBlock>
                  </FormGroup>
                </Col>
              </Row>)}
          {<this.ServiceContentListTable data={items}
                                         isPaged={false}
                                         canMultiSelect={false}
                                         isAllDataLoaded={true}
                                         selectedRowIndices={selectedIndices}
                                         onRowSelect={(newSelectedEntries: Product[], selectedRowIndices: number[]) => {
                                           this.setState(
                                               Object.assign({}, this.state, {selectedIndices: selectedRowIndices}));
                                           if (newSelectedEntries.length === 1) {
                                             this.props.selectItem(
                                                 newSelectedEntries[0] ? newSelectedEntries[0].id : null, true);
                                           } else {
                                             this.props.selectItem(null, true);
                                           }
                                         }}
                                         rowHeight={SERVICE_PRODUCT_LIST_ROW_HEIGHT}
                                         intl={this.props.intl}/>}
        </div>
    );
  }
}

export const ServiceProductList = ReorderableList<Product, WrapperProps>(injectIntl(WithApi(ServiceProductWrapper)),
    null);
export const SERVICE_PRODUCT_LIST_ROW_HEIGHT = 38;
