import * as Stomp from "@stomp/stompjs";
import * as React from "react";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {actions as notificationActions} from "../common/ui/notification/actions";
import {Logger} from "../common/util/Logger";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {actions as dataActions} from "../data/actions";
import {actions as jobActions} from "../jobs/import/actions";
import {ImportJob, Job, JobExecutionResult, PreprocessJob} from "../jobs/model";
import {actions as preprocessJobActions} from "../jobs/preprocessing/actions";
import {Service, ServiceStatus} from "../services/model";
import {actions as styleActions} from "../styles/actions";
import {WithUpdateClient, WithUpdateClientProps} from "./WithUpdateClient";

const Topics = {
  DATA_ADDED: "/imported-data/added",
  STYLE_ADDED: "/style/added",
  IMPORT_JOB_PROGRESS: "/ImportJob/progress",
  IMPORT_JOB_ADDED: "/ImportJob/added",
  IMPORT_JOB_UPDATED: "/ImportJob/updated",
  IMPORT_JOB_REMOVED: "/ImportJob/removed",
  IMPORT_JOB_FINISHED: "/ImportJob/finished",
  PREPROCESS_JOB_PROGRESS: "/PreprocessJob/progress",
  PREPROCESS_JOB_ADDED: "/PreprocessJob/added",
  PREPROCESS_JOB_UPDATED: "/PreprocessJob/updated",
  PREPROCESS_JOB_REMOVED: "/PreprocessJob/removed",
  PREPROCESS_JOB_FINISHED: "/PreprocessJob/finished",
  SERVICE_UPDATED: "/service/updated",
};

interface ReactorUpdateClientDispatchProps {
  handleDataAddedMessage: (message: Stomp.Message) => void;
  handleStyleAddedMessage: (message: Stomp.Message) => void;
  handleAddJobMessage: (message: Stomp.Message) => void;
  handleJobProgressMessage: (message: Stomp.Message) => void;
  onJobUpdate: (job: Job) => void;
  handleJobRemovedMessage: (message: Stomp.Message) => void;
  addPreprocessJob: (job: Job) => void;
  removePreprocessJob: (job: Job) => void;
  handlePreprocessJobProgressMessage: (message: Stomp.Message) => void;
  onPreprocessJobUpdate: (job: Job) => void;
  handlePreprocessJobRemovedMessage: (message: Stomp.Message) => void;
  onControlRoomNotification: (message: string) => void;
  onServiceUpdate: (service: Service) => void;
}

export class ReactorUpdateClientComponent extends React.Component<InjectedIntlProps & ReactorUpdateClientDispatchProps & WithApiProperties & WithUpdateClientProps, {}> {

  browserNotificationsAllowed: boolean;
  _logger = Logger.getLogger("ReactorUpdateClient");

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setupBrowserNotifications();
    this.subscribeToTopics();
  }

  setupBrowserNotifications() {
    if (!(window["Notification"])) {
      this.browserNotificationsAllowed = false;
    } else {
      if (window["Notification"].permission === "default") {
        window["Notification"].requestPermission((permission) => {
          this.browserNotificationsAllowed = (permission === "granted");
        });
      } else {
        this.browserNotificationsAllowed = (window["Notification"].permission === "granted");
      }
    }
  }

  notify = (message: string) => {
    if (this.browserNotificationsAllowed) {
      const browserNotification = new window["Notification"](message);
    } else {
      this.props.onControlRoomNotification(message);
    }
  }

  handlePreprocessJobAddMessage = (message: Stomp.Message) => {
    const job: PreprocessJob = JSON.parse(message.body);
    this.props.addPreprocessJob(job);
    const PREPROCESS_MESSAGES = defineMessages({
      startPreprocessing: {
        id: "studio.liveupdate.reactor-update-client.start-preprocessing",
        defaultMessage: "Started preprocessing service {service} product {product}",
        values: {service: job.serviceTitle, product: job.productTitle},
      },
    });
    const notification = this.props.intl.formatMessage(PREPROCESS_MESSAGES.startPreprocessing,
        PREPROCESS_MESSAGES.startPreprocessing.values);
    this.notify(notification);
  }

  handleImportJobFinishMessage = (message: Stomp.Message) => {
    const job: ImportJob = JSON.parse(message.body);
    // Some of the code that consumes the job updates compares the job schedule to the current state job schedule,
    // which will be null if the schedule is unset. The server does not return null properties so jobSchedule will be
    // undefined and therefore not updated in the job state. So, set it to null if it isn't included in the response.
    if (!job.jobSchedule) {
      job.jobSchedule = null;
    }
    this.props.onJobUpdate(job);
    const IMPORTJOB_MESSAGES = defineMessages({
      finishCrawl: {
        id: "studio.liveupdate.reactor-update-client.finish-crawl",
        defaultMessage: "Finished crawling {rootPathJob} with status: {status}",
        values: {
          rootPathJob: job.dataRootPath,
          status: job.lastExecutionResult.toString(),
        },
      },
    });
    this.notify(
        this.props.intl.formatMessage(IMPORTJOB_MESSAGES.finishCrawl, IMPORTJOB_MESSAGES.finishCrawl.values));
  }

  handleImportJobUpdateMessage = (message: Stomp.Message) => {
    const job: ImportJob = JSON.parse(message.body);
    this.props.onJobUpdate(job);
  }

  handlePreprocessJobUpdateMessage = (message: Stomp.Message) => {
    const job: PreprocessJob = JSON.parse(message.body);
    this.props.onPreprocessJobUpdate(job);
  }

  handlePreprocessJobFinishMessage = (message: Stomp.Message) => {
    const job: PreprocessJob = JSON.parse(message.body);
    this.sendPreprocessJobFinishedNotification(job);
    if (job.lastExecutionResult === JobExecutionResult.SUCCESS) {
      this.props.removePreprocessJob(job);
    } else {
      this.props.onPreprocessJobUpdate(job);
    }
  }

  handleServiceUpdateMessage = (message: Stomp.Message) => {
    const updatedService: Service = JSON.parse(message.body);
    // The service in the stomp message will be missing the endpoint uri -> fetch the full service from Fusion backend
    this.props.api.serviceById(updatedService.id).then(service => {
      const SERVICE_UPDATE_MESSAGES = defineMessages({
        serviceUpdate: {
          id: "studio.liveupdate.reactor-update-client.service-update",
          defaultMessage: "Service {name} has been updated",
          values: {name: service.name},
        },
        servicePreprocess: {
          id: "studio.liveupdate.reactor-update-client.service-preprocess",
          defaultMessage: "{name} service is being preprocessed.",
          values: {name: service.name},
        },
      });
      this.props.onServiceUpdate(service);
      if (service.status === ServiceStatus.RUNNING) {
        this.notify(this.props.intl.formatMessage(SERVICE_UPDATE_MESSAGES.serviceUpdate,
            SERVICE_UPDATE_MESSAGES.serviceUpdate.values));
      } else if (service.status === ServiceStatus.PENDING) {
        this.notify(this.props.intl.formatMessage(SERVICE_UPDATE_MESSAGES.servicePreprocess,
            SERVICE_UPDATE_MESSAGES.servicePreprocess.values));
      }
    }).catch((error) => this._logger.error("Error occurred while updating service.", error));
  }

  render() {
    return null; //all this component does is hook lifecycle methods
  }

  private sendPreprocessJobFinishedNotification(job: PreprocessJob) {
    const PREPROCESS_MESSAGES = defineMessages({
      finishPreprocessing: {
        id: "studio.liveupdate.reactor-update-client.finish-preprocessing",
        defaultMessage: "Finished preprocessing service {service} product {product} with status: {status}",
        values: {service: job.serviceTitle, product: job.productTitle, status: job.lastExecutionResult.toString()},
      },
    });
    const notification = this.props.intl.formatMessage(PREPROCESS_MESSAGES.finishPreprocessing,
        PREPROCESS_MESSAGES.finishPreprocessing.values);
    this.notify(notification);
  }

  private subscribeToTopics = () => {
    this.props.subscribe(Topics.DATA_ADDED, this.props.handleDataAddedMessage);
    this.props.subscribe(Topics.IMPORT_JOB_PROGRESS, this.props.handleJobProgressMessage);
    this.props.subscribe(Topics.IMPORT_JOB_ADDED, this.props.handleAddJobMessage);
    this.props.subscribe(Topics.IMPORT_JOB_UPDATED, this.handleImportJobUpdateMessage);
    this.props.subscribe(Topics.IMPORT_JOB_REMOVED, this.props.handleJobRemovedMessage);
    this.props.subscribe(Topics.IMPORT_JOB_FINISHED, this.handleImportJobFinishMessage);
    this.props.subscribe(Topics.PREPROCESS_JOB_PROGRESS, this.props.handlePreprocessJobProgressMessage);
    this.props.subscribe(Topics.PREPROCESS_JOB_ADDED, this.handlePreprocessJobAddMessage);
    this.props.subscribe(Topics.PREPROCESS_JOB_UPDATED, this.handlePreprocessJobUpdateMessage);
    this.props.subscribe(Topics.PREPROCESS_JOB_REMOVED, this.props.handlePreprocessJobRemovedMessage);
    this.props.subscribe(Topics.PREPROCESS_JOB_FINISHED, this.handlePreprocessJobFinishMessage);
    this.props.subscribe(Topics.STYLE_ADDED, this.props.handleStyleAddedMessage);
    this.props.subscribe(Topics.SERVICE_UPDATED, this.handleServiceUpdateMessage);
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDataAddedMessage: (message: Stomp.Message) => {
      dispatch(dataActions.dataAddedOnServer());
    },
    handleStyleAddedMessage: (message: Stomp.Message) => {
      dispatch(styleActions.styleAddedOnServer());
    },
    handleJobProgressMessage: (message) => {
      const body = JSON.parse(message.body);
      dispatch(jobActions.progressJob(body.jobId, body.completion));
    },
    handleAddJobMessage: (message: Stomp.Message) => {
      const job = JSON.parse(message.body);
      dispatch(jobActions.addJob(job));
    },
    onJobUpdate: (job: ImportJob) => {
      dispatch(jobActions.updateJobOnClient(job));
    },
    handleJobRemovedMessage: (message: Stomp.Message) => {
      const job = JSON.parse(message.body);
      dispatch(jobActions.removeJob(job.id));
    },
    handlePreprocessJobProgressMessage: (message) => {
      const body = JSON.parse(message.body);
      dispatch(preprocessJobActions.progressJob(body.jobId, body.completion));
    },
    addPreprocessJob: (job: PreprocessJob) => {
      dispatch(preprocessJobActions.addJob(job));
    },
    removePreprocessJob: (job: PreprocessJob) => {
      dispatch(preprocessJobActions.removeJob(job.id));
    },
    onPreprocessJobUpdate: (job: PreprocessJob) => {
      dispatch(preprocessJobActions.updateJobOnClient(job));
    },
    handlePreprocessJobRemovedMessage: (message: Stomp.Message) => {
      const job = JSON.parse(message.body);
      dispatch(preprocessJobActions.removeJob(job.id));
    },
    onControlRoomNotification: (notificationMessage: string) => {
      dispatch(notificationActions.showInfoNotification(notificationMessage));
    },
    onServiceUpdate: (service: Service) => {
      dispatch(jobActions.updateServiceOnClient(service));
    },
  };
};

export const ReactorUpdateClient = connect(null, mapDispatchToProps)(
    WithApi(WithUpdateClient(injectIntl(ReactorUpdateClientComponent))));
